




















































import Vue from 'vue';
import { mapState } from 'vuex';
import html2canvas from 'html2canvas';
import {
  bucketCdnHost,
  bucketHost,

} from '~/util/common';
export default Vue.extend({
  name: 'Booth',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
    },
  },
  data(): any {
    return {
      bgImage:
        'https://dcdn-jufair.jufair.com/jufair/mfKtYsM2QxMPQDSpYBaeKRcKBfW2p7Jh.png',
      bgHeight: '',
      qrcodeUrl: '',
      // imgUrl1: "https://dcdn-jufair.jufair.com/jufair/wNr4ek7aRFb6mAnbsXSYRtmcyY8kZWy8.png",  //测试
      imgUrl1:
        'https://dcdn-jufair.jufair.com/jufair/JSSacTBfA5p8acFmre5XpM4WhHfn5seY.png',
      downLoading: false,
      lang:''
    };
  },
  computed: {
    ...mapState({
      isLogin: (state: any) => state.global.isLogin,
    }),
  },
  watch: {
    value(e) {
      if (e) {
        this.getPoster();
      }
    },
  },
  filters: {
    getYear(str: string) {
      return str.substr(0, 4);
    },
    getStart(str: string) {
      return str.replace(new RegExp('-', 'g'), '.');
    },
    getEnd(str: string) {
      let s = str.replace(new RegExp('-', 'g'), '.');
      return s.substr(5, 10);
    },
    logo(value: string): string {
      let parse: any = value;
      if (typeof value === 'string') {
        try {
          parse = JSON.parse(value)[0]
            .replace('http://', 'https://')
            .replace(bucketHost, bucketCdnHost);
        } catch (error) {
          parse = value;
          console.error(error);
        }
      }
      return parse + '!logo';
    },
  },
  methods: {
    handleClose() {
      this.$emit('input', false);
    },
    // 获取海报图片
    async getPoster() {
      this.getQrcode();
      this.getImageUrl();
      const res = await this.$axios.get(
        `/api/poster/getLatestPoster?tag=${this.info.posterTag}`
      );
      if (res) {
        this.bgImage = res.data
          ? res.data.bgImage ||
            'https://dcdn-jufair.jufair.com/jufair/mfKtYsM2QxMPQDSpYBaeKRcKBfW2p7Jh.png'
          : 'https://dcdn-jufair.jufair.com/jufair/mfKtYsM2QxMPQDSpYBaeKRcKBfW2p7Jh.png';

        // setTimeout(() => {
        //   let resssd:any = document.getElementById("bgImageDoc")
        //   if(!resssd.offsetHeight) this.getPoster()
        //   this.bgHeight = resssd.offsetHeight+"px"
        // })
        // this.$nextTick(() => {
        //   let resssd:any = document.getElementById("bgImageDoc")
        //   console.log(130, resssd, resssd.offsetHeight)
        //   this.bgHeight = resssd.offsetHeight+"px"
        // })
      }
    },
    async getQrcode() {
      // this.$axios.
      const res = await this.$axios.post(
        `/api/wx/mini_app/wxa_code/create`,
        {
          page: 'views/exh-info/exh-info',
          scene: `id=${this.info.exhibitionId}`,
          checkPath: true,
          autoColor: false,
          lineColor: { r: 0, g: 0, b: 0 },
          envVersion: 'release', // 正式版为 "release"，体验版为 "trial"，开发版为 "develop"
        },
        { responseType: 'arraybuffer' }
      );

      const blob = new Blob([res], { type: 'image/png' });
      const blobUrl = URL.createObjectURL(blob);
      this.qrcodeUrl = blobUrl;
      if (res) {
        // this.bgImage = res.data.bgImage
      }
    },
    async getImageUrl() {
      // let res = await this.$axios.get(`https://dcdn-jufair.jufair.com/jufair/wNr4ek7aRFb6mAnbsXSYRtmcyY8kZWy8.png`, {}, { responseType: 'arraybuffer' })
      // let blob = new Blob([res], { type: "image/png" });
      // let blobUrl = URL.createObjectURL(blob);
      // this.imgUrl1 = blobUrl;
      // this.imgUrl1 = "https://dcdn-jufair.jufair.com/jufair/wNr4ek7aRFb6mAnbsXSYRtmcyY8kZWy8.png"
    },
    convertImageToBase64(image: any) {
      const canvas = document.createElement('canvas');
      const context: any = canvas.getContext('2d');
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);
      return canvas.toDataURL('image/png'); // 根据需要指定输出格式
    },
    getBase64Image(src: string) {
      return new Promise((resolve) => {
        const img = new Image();
        img.crossOrigin = '';
        img.src = src;
        img.onload = function () {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0, img.width, img.height);
          const ext = img.src
            .substring(img.src.lastIndexOf('.') + 1)
            .toLowerCase();
          const dataURL = canvas.toDataURL('image/' + ext);
          resolve(dataURL);
        };
      });
    },
    getBase64Image22(src: string) {
      return new Promise((resolve) => {
        let xhr = new XMLHttpRequest();
        xhr.open('get', src, true);
        xhr.responseType = 'blob';
        xhr.onload = function () {
          if (this.status == 200) {
            let blob = this.response;
            let oFileReader = new FileReader();
            oFileReader.onloadend = function (e: any) {
              const base64 = e.target.result;
              resolve(base64);
            };
            oFileReader.readAsDataURL(blob);
          }
        };
        xhr.send();
      });
    },
    handleConfirm() {
      this.downLoading = true;
      // this.$axios.get("https://jufair-test.oss-cn-shanghai.aliyuncs.com/jufair/6J7WQG64hesR2xiERR6dzSbz8TfXW5NW.png").then((res:any) => {
      //   let blob = new Blob([res], { type: 'image/png' })
      //   let reader = new FileReader()
      //   reader.readAsDataURL(blob)
      //   reader.onload = () => {
      //     // this.bgImage = reader.result
      //     console.log(58,reader.result)
      //   }
      // })

      // 成功
      // this.getBase64Image22("https://jufair-test.oss-cn-shanghai.aliyuncs.com/jufair/6J7WQG64hesR2xiERR6dzSbz8TfXW5NW.png").then((res:any) => {
      //   console.log(87,res)
      // }).catch((err:any) => {
      //   console.log(88,err)
      // })

      // 获取需要生成图片的DOM元素
      const element: any = document.getElementById('poster');

      // 使用html2canvas生成图片
      html2canvas(element, { scale: 2, useCORS: true }).then((canvas) => {
        let url = canvas.toDataURL('image/png');
        // this.bgImage = url
        // canvas就是生成的图片了

        // 若想保存为图片文件，则可以创建一个a标签，设置href属性为dataURL形式的base64编码数据，点击下载链接即可保存图片
        const link = document.createElement('a');
        link.download = `${this.info.exhibitionName}.png` || 'image.png';
        link.href = canvas.toDataURL();
        link.click();
        setTimeout(() => {
          this.downLoading = false;
          this.$message.success('下载成功!');
        }, 3000);
      });
    },
  },
  mounted() {
    this.lang  = this.$route.query.lang || ""
    // this.getPoster()
  },
});
